<template>
  <div>
    <HeaderPanel
      title="EXTRA POINT"
      :filter="filter"
      placeholder="Search Name"
      @sidebar="sidebarFilterShow"
      @search="getList"
      :hasDropdown="false"
      routerPath="/extrapoint/details/0"
    />
    <b-sidebar
      id="sidebarFilter"
      v-model="isShowSidebar"
      title="FILTER"
      aria-labelledby="sidebar-no-header-title"
      no-header
      backdrop
      shadow
      right
      ref="sidebarFilter"
      width="85vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter"> Filter </b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="clearFilter">
              x <span class="text-under">Clear Filter</span>
            </button>
          </b-col>
        </b-row>
        <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
        <b-input-group class="my-2">
          <b-form-input
            type="search"
            class="search-bar"
            placeholder="Search Name"
            v-model="filter.search"
          >
          </b-form-input>
        </b-input-group>
      </div>
      <div class="px-3">
        <div class="mt-3">
          <div class="font-weight-bold mb-2">Valid From</div>
          <div :class="['input-container']">
            <datetime
              :input-style="styleDatetime"
              v-model="filter.startDate"
              type="datetime"
              class="w-100"
              placeholder="Please Select Date"
              @close="changeStartDate"
              ref="startDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.startDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer color-primary"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="font-weight-bold mb-2">Valid To</div>
          <div :class="['input-container']">
            <datetime
              :input-style="styleDatetime"
              v-model="filter.endDate"
              type="datetime"
              class="w-100"
              placeholder="Please Select Date"
              :min-datetime="filter.startDate"
              @close="onChangeEndDate"
              ref="endDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.endDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer color-primary"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="font-weight-bold">Status</div>
          <b-form-group>
            <div>
              <b-form-checkbox
                class="mb-2 w-50"
                v-model="selectAllCb"
                @change="selectAllStatus($event)"
                >All</b-form-checkbox
              >
              <b-form-checkbox
                :value="1"
                class="ml-4 w-50"
                v-model="checkboxStatus"
                @change="selectStatus($event)"
                >Active</b-form-checkbox
              >
              <b-form-checkbox
                :value="0"
                class="ml-4 w-50"
                v-model="checkboxStatus"
                @change="selectStatus($event)"
                >Inactive</b-form-checkbox
              >
            </div>
          </b-form-group>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
              >Cancel</b-button
            >
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              id="handle-search-filter"
              class="text-body rounded-pill btn-main"
              @click="handleSearch"
            >
              Search
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="data">
              <div v-if="data.item.name" class="pl-2">
                <b-button variant="link" class="px-1 py-0">
                  <router-link :to="'/extrapoint/details/' + data.item.id">
                    <u>{{ data.item.name }}</u>
                  </router-link>
                </b-button>
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:cell(quantity)="data">
              <div v-if="data.item.quantity">
                {{ data.item.quantity | numeral("0,0") }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:cell(start_datetime)="data">
              <div v-if="data.item.start_datetime">
                <div>
                  {{ data.item.start_datetime | moment($formatDateNew) }}
                </div>
                <div class="time-color">
                  {{ data.item.start_datetime | moment("HH:mm:ss") }}
                </div>
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:cell(end_datetime)="data">
              <div v-if="data.item.end_datetime">
                <div>{{ data.item.end_datetime | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.end_datetime | moment("HH:mm:ss") }}
                </div>
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:cell(status)="data">
              <div v-if="data.item.status == 1" class="text-success">
                Active
              </div>
              <div v-else class="text-error">Inactive</div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import HeaderPanel from "@/components/HeaderPanel";
export default {
  components: {
    HeaderPanel,
  },
  name: "ExtraPointIndex",
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          class: "text-nowrap text-center",
          thStyle: {
            width: "35%",
          },
        },
        {
          key: "quantity",
          label: "Number Of Branches",
          class: "text-nowrap",
          thStyle: {
            width: "10%",
          },
        },
        {
          key: "start_datetime",
          label: "Valid From",
          class: "text-nowrap",
          thStyle: {
            width: "20%",
          },
        },
        {
          key: "end_datetime",
          label: "Valid To",
          class: "text-nowrap",
          thStyle: {
            width: "20%",
          },
        },
        {
          key: "status",
          label: "Status",
          class: "text-nowrap",
          thStyle: {
            width: "15%",
          },
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      selectAllCb: false,
      isShowSidebar: false,
      checkboxStatus: [],
      filter: {
        search: "",
        page: 1,
        take: 10,
        status: null,
        startDate: "",
        endDate: "",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let query = this.setQuery();
      await this.$store.dispatch("getExtraPointList", query);
      let data = this.$store.state.extraPoint.extraPointList;
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.total;
        this.isBusy = false;
      }
    },
    handleSearch(e) {
      if (!e) {
        this.getList();
      }
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.filter.page = 1;
          this.hide();
          this.getList();
        }
      } else if (e.type === "click") {
        this.filter.page = 1;
        this.hide();
        this.getList();
      }
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getList();
    },
    getDataByStatus() {
      this.$refs.filterSidebar.hide();
      this.getList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: null,
        startDate: "",
        endDate: "",
      };
      this.checkboxStatus = [];
      this.selectAllCb = false;
    },
    sidebarFilterShow() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    setQuery() {
      let query = [];
      if (this.filter.search) {
        query.push(`search=${this.filter.search}`);
      }
      if (this.filter.page) {
        query.push(`page=${this.filter.page}`);
      }
      if (this.filter.take) {
        query.push(`take=${this.filter.take}`);
      }
      if (this.filter.status == 0 || this.filter.status == 1) {
        query.push(`status=${this.filter.status}`);
      }
      if (this.filter.startDate) {
        query.push(`startDate=${this.filter.startDate.substr(0, 10)}`);
      }
      if (this.filter.endDate) {
        query.push(`endDate=${this.filter.endDate.substr(0, 10)}`);
      }
      if (query.length > 0) {
        return `?${query.join("&")}`;
      } else {
        return ``;
      }
    },
    selectAllStatus(value) {
      if (value) {
        this.filter.status = null;
        this.checkboxStatus = [1, 0];
      } else {
        this.filter.status = null;
        this.checkboxStatus = [];
      }
    },
    selectStatus(value) {
      if (value.length == 2) {
        this.filter.status = null;
        this.checkboxStatus = [1, 0];
        this.selectAllCb = true;
      } else if (value.length == 1) {
        this.filter.status = value[0];
        this.selectAllCb = false;
      } else {
        this.filter.status = null;
        this.checkboxStatus = [];
        this.selectAllCb = false;
      }
    },
    changeStartDate() {
      if (!this.filter.endDate) {
        this.filter.endDate = this.filter.startDate;
      }
      if (this.filter.startDate >= this.filter.endDate) {
        this.filter.endDate = moment(this.filter.startDate)
          .add("day", 0)
          .format();
      }
    },
    onChangeEndDate() {
      if (!this.filter.startDate) {
        this.filter.startDate = this.filter.endDate;
      }
    },
  },
};
</script>

<style scoped>
.text-gray {
  color: #ababab;
}
.btn-link {
  color: #000;
}
.button-extra {
  width: 250px !important;
  height: 38px;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.button-extra-fill {
  width: 120px !important;
  height: 38px;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}

.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.form-control {
  height: 100%;
}
</style>
